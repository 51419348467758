import React from 'react';
import styled from 'styled-components';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

const ButtonContainer = styled.div`
  width: 256px;
  height: 80px;
  margin: 20px;
`;

export const AppStoreButton = ({ children, href }) => (
  <ButtonContainer>
    <OutboundLink target="_blank" rel="noopener noreferrer" href={href}>
      {children}
    </OutboundLink>
  </ButtonContainer>
);
