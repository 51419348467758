import React from 'react';
import styled from 'styled-components';
import { injectIntl } from 'gatsby-plugin-intl';

import { ReadMore, NonStretchedImage, AppStoreButtons } from 'components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100vh;
  min-height: 700px;
  width: var(--container-width);
`;

const Hero = styled.div`
  margin-bottom: 2vh;
`;

const Title = styled.h1`
  display: block;
  margin: 80px auto 40px auto;
  font-size: 60px;
  line-height: 1.2;
  text-align: center;
  font-weight: 700;
  letter-spacing: -4px;
  -webkit-background-clip: text;

  @media (max-width: 768px) {
    font-size: 45px;
  }
`;

const Subtitle = styled.p`
  max-width: 28em;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.6;
  color: var(--dark-color-light);
  @media (max-width: 768px) {
    font-size: 19px;
  }
`;

export const Welcome = injectIntl(
  ({ intl, logoFluid, appStoreFluid, playStoreFluid }) => (
    <Container>
      <Hero>
        <NonStretchedImage fluid={logoFluid} alt="Leetags logo" />
        <Title>{intl.formatMessage({ id: 'hero_text' })}</Title>
        <Subtitle>{intl.formatMessage({ id: 'home_text' })}</Subtitle>
        <AppStoreButtons appStore={appStoreFluid} playStore={playStoreFluid} />
      </Hero>
      <ReadMore href="#faq" />
    </Container>
  ),
);
