import React from 'react';
import styled from 'styled-components';

import { AppStoreButton, NonStretchedImage } from 'components';

const Container = styled.div`
  width: var(--container-width);
  display: flex;
  align-self: center;
  justify-content: center;
`;

export const AppStoreButtons = ({ appStore, playStore }) => (
  <Container>
    <AppStoreButton href="https://play.google.com/store/apps/details?id=com.leetags">
      <NonStretchedImage fluid={playStore} alt="Play Store Get" />
    </AppStoreButton>
    <AppStoreButton href="https://itunes.apple.com/app/leetags-relevant-hashtags/id1230168971">
      <NonStretchedImage fluid={appStore} alt="App Store Get" />
    </AppStoreButton>
  </Container>
);
