import React from 'react';
import styled from 'styled-components';
import { injectIntl } from 'gatsby-plugin-intl';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

const Container = styled.footer`
  padding-top: 1vh;
  padding-bottom: 1vh;
  justify-content: center;
  font-size: 17px;
  display: flex;
  width: var(--container-width);

  @media (max-width: 768px) {
    font-size: 16px;
  }

  a,
  p {
    padding: 10px;
  }
`;

export const Footer = injectIntl(({ intl }) => (
  <Container>
    <p>
      <OutboundLink
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.instagram.com/bruceleetags/"
      >
        Instagram
      </OutboundLink>
      <OutboundLink
        target="_blank"
        rel="noopener noreferrer"
        href="https://twitter.com/bruceleetags/"
      >
        Twitter
      </OutboundLink>
      <OutboundLink
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.facebook.com/bruceleetags/"
      >
        Facebook
      </OutboundLink>
      <OutboundLink
        target="_blank"
        rel="noopener noreferrer"
        href="mailto:ibnclaudius@leetags.com?subject=Contact - Leetags Site"
      >
        {intl.formatMessage({ id: 'email_link' })}
      </OutboundLink>
      <OutboundLink
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.iubenda.com/privacy-policy/8100465"
      >
        {intl.formatMessage({ id: 'privacy_policy_link' })}
      </OutboundLink>
      <OutboundLink
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.iubenda.com/privacy-policy/8100465/cookie-policy"
      >
        {intl.formatMessage({ id: 'cookies_policy_link' })}
      </OutboundLink>
      © {new Date().getFullYear()} Leetags.
    </p>
  </Container>
));
