import React from 'react';
import styled from 'styled-components';

import { GlobalStyles } from 'styles';

const Content = styled.div`
  margin: 0 auto;
  width: var(--container-width);
  max-width: 2000px;

  @media (max-width: 849px) {
    padding: 0;
  }
`;

export const Layout = ({ children }) => (
  <>
    <GlobalStyles />
    <Content>{children}</Content>
  </>
);
