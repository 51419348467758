import React from 'react';
import styled from 'styled-components';
import { injectIntl } from 'gatsby-plugin-intl';

const Container = styled.div`
  text-align: center;
  margin: 120px auto 25px auto;
`;

const Text = styled.a`
  font-size: 25px;
  font-weight: 300;
`;

export const ReadMore = injectIntl(({ intl, href }) => (
  <Container>
    <Text href={href}>{intl.formatMessage({ id: 'read_more_link' })}</Text>
  </Container>
));
