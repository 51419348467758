import React from 'react';
import styled from 'styled-components';
import { injectIntl } from 'gatsby-plugin-intl';

const Container = styled.div`
  text-align: center;
  list-style-position: inside;
  padding: 40px;
  background-color: var(--dark-color);
  color: var(--accent-color);
  width: var(--container-width);

  ul {
    margin: 0;
  }
`;

const FaqTitle = styled.h2`
  font-size: 40px;
`;

const FaqList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FaqListItem = styled.li`
  width: 550px;
  margin 3vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Question = styled.h3`
  font-size: 25px;
  font-weight: 700;
  align-self: center;
`;

const Answer = styled.p`
  text-align: center;
  word-wrap: break-word;
  font-weight: 300;
  margin-top: 20px;
  color: var(--light-color-translucent);
`;

const faq = [...Array(5).keys()];

export const Faq = injectIntl(({ intl }) => (
  <Container id="faq">
    <FaqTitle>{intl.formatMessage({ id: 'faq_title' })}</FaqTitle>
    <FaqList>
      {faq.map((number) => (
        <FaqListItem key={number}>
          <Question>
            {intl.formatMessage({ id: `question_text_${number}` })}
          </Question>
          <Answer>{intl.formatMessage({ id: `answer_text_${number}` })}</Answer>
        </FaqListItem>
      ))}
    </FaqList>
  </Container>
));
